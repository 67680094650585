import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function HandMade() {
  return (
    <FormControl sx={{ m: 1 , width: '100%'}}>
        <InputLabel htmlFor="grouped-select">
            ¿Mano de obra incluida?
        </InputLabel>
        <Select defaultValue="No" id="grouped-select" label="Grouping" name='hand_made'
          sx={{
            borderRadius: '0',
            border: 'none', 
            margin: '2rem 0',
            borderBottom: '1px solid black', 
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            }
          }}
        >
          <ListSubheader>Mano de obra</ListSubheader>
          <MenuItem value={'Si'}>Si</MenuItem>
          <MenuItem value={'No'}>No</MenuItem>
        </Select>
      </FormControl>
  );
}
// Import React Library.
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Import Images
import steel1 from '../assets/images/canos/hierros-1.png';
import steel2 from '../assets/images/canos/hierros-2.png';
import steel3 from '../assets/images/canos/hierros-3.png';
import steel4 from '../assets/images/canos/hierros-4.png';
import steel5 from '../assets/images/canos/hierros-5.png';
import steel6 from '../assets/images/canos/hierros-6.png';
import steel7 from '../assets/images/canos/hierros-7.png';

// Galvanized Iron Component.
const GalvanizedIron = () => {
    return (
        <>
            <Navbar />
            <Cover section='steel' coverTitle='Caños de hierro' coverSubtitle='Galvanizados en frio - Galvanizados en caliente - Comunes'/>
            <WhatsappButton />
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Hierros <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Hierro galvanizado de 20x20mm'
                                cardDescription='Hierros galvanizados en caliente de 20x20mm. Espesor 1.6mm. Perfil de 6.00mts'
                                cardPrice='USD 25'
                                cardImage={steel1}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en caliente'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 40x40mm'
                                cardDescription='Hierros galvanizados en caliente de 40x40mm. Espesor 1.7mm. Perfil de 6.00mts'
                                cardPrice='USD 35'
                                cardImage={steel1}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en caliente'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 80x80mm'
                                cardDescription='Hierros galvanizados en caliente de 80x80mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 65'
                                cardImage={steel1}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en caliente'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 80x80mm'
                                cardDescription='Hierros galvanizados de 80x80mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 65'
                                cardImage={steel1}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en frio'
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 100x100mm'
                                cardDescription='Hierros galvanizados en caliente de 100x100mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 80'
                                cardImage={steel1}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en caliente'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 20x40mm'
                                cardDescription='Hierros galvanizados en caliente de 20x40mm. Espesor 1.7mm. Perfil de 6.00mts'
                                cardPrice='USD 30'
                                cardImage={steel2}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en caliente'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 40x80mm'
                                cardDescription='Hierros galvanizados en caliente de 40x80mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 50'
                                cardImage={steel2}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en caliente'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 50x100mm'
                                cardDescription='Hierros galvanizados en caliente de 50x100mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 55'
                                cardImage={steel2}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en caliente'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Hierro galvanizado de 50x100mm'
                                cardDescription='Hierros galvanizados de 50x100mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 55'
                                cardImage={steel2}
                                productSize='6.00mts'
                                productColor='Gris galvanizado'
                                badgePanelType='Galvanizado en frio'
                            />

                            <ProductCard 
                                cardTitle='Hierro negro de 40x40mm'
                                cardDescription='Hierros negro de 40x40mm. Espesor 1.6mm. Perfil de 6.00mts'
                                cardPrice='USD 24.55'
                                cardImage={steel3}
                                productSize='6.00mts'
                                productColor='Negro'
                                badgePanelType='Hierro negro'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Hierro negro de 100x100mm'
                                cardDescription='Hierros negro de 100x100mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 62.05'
                                cardImage={steel3}
                                productSize='6.00mts'
                                productColor='Negro'
                                badgePanelType='Hierro negro'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Hierro negro de 40x80mm'
                                cardDescription='Hierros negro de 40x80mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 29.75'
                                cardImage={steel4}
                                productSize='6.00mts'
                                productColor='Negro'
                                badgePanelType='Hierro negro'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Hierro negro de 50x100mm'
                                cardDescription='Hierros negro de 50x100mm. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 46.75'
                                cardImage={steel4}
                                productSize='6.00mts'
                                productColor='Negro'
                                badgePanelType='Hierro negro'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Perfil "C" Galvanizado 80x30x15'
                                cardDescription='Perfil "C" Galvanizado 80x30x15. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 30'
                                cardImage={steel5}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Perfil C - Galvanizado'
                            />

                            <ProductCard 
                                cardTitle='Perfil "C" Galvanizado 160x50x15'
                                cardDescription='Perfil "C" Galvanizado 160x50x15. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 59'
                                cardImage={steel5}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Perfil C - Galvanizado'
                            />

                            <ProductCard 
                                cardTitle='Perfil "C" Galvanizado 180x60x15'
                                cardDescription='Perfil "C" Galvanizado 180x60x15. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 79'
                                cardImage={steel5}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Perfil C - Galvanizado'
                            />

                            <ProductCard 
                                cardTitle='Perfil "C" Galvanizado 200x70x15'
                                cardDescription='Perfil "C" Galvanizado 200x70x15. Espesor 2.5mm. Perfil de 6.00mts'
                                cardPrice='USD 89'
                                cardImage={steel5}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Perfil C - Galvanizado'
                            />

                            <ProductCard 
                                cardTitle='Perfil "U" Galvanizado 5cm'
                                cardDescription='Perfil "U" Galvanizado 50m x 30cm de alto. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 42'
                                cardImage={steel6}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Perfil C - Galvanizado'
                            />

                            <ProductCard 
                                cardTitle='Perfil "U" Galvanizado 10cm'
                                cardDescription='Perfil "U" Galvanizado 100m x 30cm de alto. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 48'
                                cardImage={steel6}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Perfil C - Galvanizado'
                            />

                            <ProductCard 
                                cardTitle='Angulo "L" Galvanizado 40x40'
                                cardDescription='Angulo "L" Galvanizado 40x40. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 35'
                                cardImage={steel7}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Angulo galvanizado'
                            />

                            <ProductCard 
                                cardTitle='Angulo "L" Galvanizado 50x50'
                                cardDescription='Angulo "L" Galvanizado 50x50. Espesor 2mm. Perfil de 6.00mts'
                                cardPrice='USD 40'
                                cardImage={steel7}
                                productSize='6.00mts'
                                productColor='Galvanizado'
                                badgePanelType='Angulo galvanizado'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Galvanized Iron Component.
export default GalvanizedIron;
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function PanelesType() {
  return (
    <FormControl sx={{ m: 1 , width: '100%'}}>
        <InputLabel htmlFor="grouped-select">
            Tipo de Isopanel
        </InputLabel>
        <Select defaultValue="Empty" id="grouped-select" label="Grouping" name='panel_type'
          sx={{
            borderRadius: '0',
            border: 'none', 
            margin: '2rem 0',
            borderBottom: '1px solid black', 
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            }
          }}
        >
          <ListSubheader>Techo engrafado</ListSubheader>
          <MenuItem value={'EPS 10cm - Engrafado'}>EPS 10cm (Ancho util 1.135mt)</MenuItem>
          <MenuItem value={'EPS 15cm - Engrafado'}>EPS 15cm (Ancho util 1.135mt)</MenuItem>
          <MenuItem value={'EPS 20cm - Engrafado'}>EPS 20cm (Ancho util 1.135mt)</MenuItem>
          <MenuItem value={'EPS 25cm - Engrafado'}>EPS 25cm (Ancho util 1.135mt)</MenuItem>

          <ListSubheader>Techo trapezoidal</ListSubheader>
          <MenuItem value={'Panel de foil 3cm - Trapezoidal'}>Foil de 3cm (Ancho util 95cm)</MenuItem>
          <MenuItem value={'EPS 5cm - Trapezoidal'}>EPS 5cm (Ancho util 95cm)</MenuItem>
          <MenuItem value={'EPS 10cm - Trapezoidal'}>EPS 10cm (Ancho util 95cm)</MenuItem>

          <ListSubheader>Pared</ListSubheader>
          <MenuItem value={'EPS 5cm - 95cm - pared'}>EPS 5cm (Ancho util 95cm)</MenuItem>
          <MenuItem value={'EPS 10cm - 95cm - pared'}>EPS 10cm (Ancho util 95cm)</MenuItem>
          <MenuItem value={'EPS 10cm - 1.135 - pared'}>EPS 10cm (Ancho util 1.135mt)</MenuItem>
          <MenuItem value={'EPS 15cm - 1.135 - pared'}>EPS 15cm (Ancho util 1.135mt)</MenuItem>
          <MenuItem value={'EPS 20cm - 1.135 - pared'}>EPS 20cm (Ancho util 1.135mt)</MenuItem>
          <MenuItem value={'EPS 25cm - 1.135 - pared'}>EPS 25cm (Ancho util 1.135mt)</MenuItem>

          <ListSubheader>PIR</ListSubheader>
          <MenuItem value={'PIR 5cm - Engrafado'}>PIR 5cm (Ancho util 1.12mt)</MenuItem>
          <MenuItem value={'PIR 10cm - Engrafado'}>PIR 10cm(Ancho util 1.12mt)</MenuItem>
          <MenuItem value={'PIR 15cm - Engrafado'}>EPS 15cm (Ancho util 1.12mt)</MenuItem>
          <MenuItem value={'PIR 20cm - Engrafado'}>PIR 20cm(Ancho util 1.12mt)</MenuItem>
          <MenuItem value={'PIR 25cm - Engrafado'}>EPS 25cm (Ancho util 1.12mt)</MenuItem>
        </Select>
      </FormControl>
  );
}
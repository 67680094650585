// Import React Library
import React from 'react';
import {useState} from 'react';

// Import Material UI Components
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

// Import Bootstrap Modal
import Modal from 'react-bootstrap/Modal';

// Import Images
import structure2 from '../assets/images/structures/structure-2.jpg';
import structure3 from '../assets/images/structures/structures-3.jpg';
import structure4 from '../assets/images/structures/structures-4.jpg';
import structure5 from '../assets/images/structures/structures-5.jpg';
import structure6 from '../assets/images/structures/structures-6.jpg';
import structure7 from '../assets/images/structures/structures-7.jpg';
import structure8 from '../assets/images/structures/structures-8.jpg';
import structure9 from '../assets/images/structures/structures-9.jpg';
import structure10 from '../assets/images/structures/structures-10.jpg';
import structure11 from '../assets/images/structures/structures-11.jpg';
import structure12 from '../assets/images/structures/structures-12.jpg';
import structure13 from '../assets/images/structures/structures-13.jpg';
import structure14 from '../assets/images/structures/structures-14.jpg';
import structure15 from '../assets/images/structures/structures-15.jpg';
import structure16 from '../assets/images/structures/structures-16.jpg';
import structure17 from '../assets/images/structures/structures-17.jpg';
import structure18 from '../assets/images/structures/structures-18.jpg';
import structure19 from '../assets/images/structures/structures-19.jpg';
import structure20 from '../assets/images/structures/structures-20.jpg';
import structure21 from '../assets/images/structures/structures-21.jpg';
import structure22 from '../assets/images/structures/structures-22.jpg';

export default function StructuresGallery() {
    // Handle Modal Component State 
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [constructionImage , setConstructionImage] = useState('');
    const [constructionTitle , setConstructionTitle] = useState('');

    function handleShow(breakpoint , imgRoute, title) {
        setFullscreen(breakpoint);
        setShow(true);
        setConstructionImage(imgRoute);
        setConstructionTitle(title);
    }

    return (
        <>
            <ImageList sx={{ width: '80%', height: '100%' }}>
            {itemData.map((item) => (
                <ImageListItem key={item.img}>
                <img
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                    onClick={() => handleShow(true , item.img, item.title)}
                    style={{cursor: 'pointer'}}
                />
                <ImageListItemBar
                    title={item.title}
                    subtitle={item.author}
                    actionIcon={
                    <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                    >
                        <InfoIcon />
                    </IconButton>
                    }
                />
                </ImageListItem>
            ))}
            </ImageList>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} className='product-modal'>
                <Modal.Header closeButton>{constructionTitle}</Modal.Header>
                <Modal.Body>
                    <img src={constructionImage} alt='Construction Image' className='modal-image'/>
                </Modal.Body>
            </Modal>
        </>
    );
}

const itemData = [
  {
    img: structure2,
    title: 'Estructura metalica',
    author: 'Correa',
    rows: 3,
    cols: 3,
    featured: true,
  },
  {
    img: structure3,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure4,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure5,
    title: 'Estructura metalica',
    author: 'Correa',
    cols: 3,
  },
  {
    img: structure6,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure7,
    title: 'Estructura metalica',
    author: 'Correa',
    rows: 3,
    cols: 3,
    featured: true,
  },
  {
    img: structure8,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure9,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure10,
    title: 'Estructura metalica',
    author: 'Correa',
    rows: 3,
    cols: 3,
  },
  {
    img: structure11,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure12,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure13,
    title: 'Estructura metalica',
    author: 'Correa',
    cols: 3,
  },
  {
    img: structure14,
    title: 'Estructura metalica',
    author: 'Correa',
    rows: 3,
    cols: 3,
    featured: true,
  },
  {
    img: structure15,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure16,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure17,
    title: 'Estructura metalica',
    author: 'Correa',
    rows: 3,
    cols: 3,
  },
  {
    img: structure18,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure19,
    title: 'Estructura metalica',
    author: 'Correa',
  },
  {
    img: structure20,
    title: 'Estructura metalica',
    author: 'Correa',
    cols: 2,
  },
  {
    img: structure21,
    title: 'Estructura metalica',
    author: 'Correa',
    rows: 3,
    cols: 3,
    featured: true,
  },
  {
    img: structure22,
    title: 'Estructura metalica',
    author: 'Correa',
  }
];

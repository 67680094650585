// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import ProductCard from '../components/ProductCard';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Import Doors Images
import door1 from '../assets/images/doors/brown-door.png';
import door2 from '../assets/images/doors/white-door.png';

// Import Window Image
import window1 from '../assets/images/windows/window-1.png';
import window2 from '../assets/images/windows/window-2.png';
import window3 from '../assets/images/windows/window-3.png';

// Windows Component
const Windows = () => {
    return (
        <>
            <Navbar />
            <Cover section='windows' coverTitle='Aberturas' coverSubtitle='Ventanas - Puertas Ventana - Puertas de Madera'/>
            <WhatsappButton />
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Ventanas <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Puerta ventana DVH 2000 x 2350'
                                cardDescription='Puerta ventana DVH - Alma en hierro - 8cm de espesor - Completa con mosquitero - Camara de aire.'
                                cardPrice='USD 926.50'
                                cardImage={window1}
                                productSize='2000 x 2350'
                                productColor='Blanca'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Puerta ventana DVH 2500 x 2350'
                                cardDescription='Puerta ventana DVH - Alma en hierro - 8cm de espesor - Completa con mosquitero - Camara de aire.'
                                cardPrice='USD 1096.50'
                                cardImage={window1}
                                productSize='2500 x 2350'
                                productColor='Blanca'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Ventana DVH 2000 x 600'
                                cardDescription='Ventana corrdeiza DVH - Alma en hierro - 8cm de espesor - Completa con mosquitero - Camara de aire.'
                                cardPrice='USD 288.15'
                                cardImage={window2}
                                productSize='2000 x 600'
                                productColor='Blanca'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Ventana DVH 2000 x 1200'
                                cardDescription='Ventana corrdeiza DVH - Alma en hierro - 8cm de espesor - Completa con mosquitero - Camara de aire.'
                                cardPrice='USD 458.15'
                                cardImage={window2}
                                productSize='2000 x 1200'
                                productColor='Blanca'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Ventana DVH 2000 x 1600'
                                cardDescription='Ventana corrdeiza DVH - Alma en hierro - 8cm de espesor - Completa con mosquitero - Camara de aire.'
                                cardPrice='USD 568.65'
                                cardImage={window2}
                                productSize='2000 x 1600'
                                productColor='Blanca'
                                offer={'15% off'}
                            />

                            <ProductCard 
                                cardTitle='Ventana DVH Oscilo batiente '
                                cardDescription='Ventana corrdeiza DVH - Alma en hierro - 8cm de espesor - Completa con mosquitero - Camara de aire.'
                                cardPrice='USD 265.20'
                                cardImage={window3}
                                productSize='600 x 1200'
                                productColor='Blanca'
                                offer={'15% off'}
                            />
                        </div>
                    </div>
                </div>

                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Puertas <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Puerta de madera'
                                cardDescription='Puerta interior de madera laminada en PVC.'
                                cardPrice='USD 130'
                                cardImage={door1}
                                productSize='0.80 x 2.10'
                                productColor='Marron oscuro'
                                offer={'30% off'}
                            />

                            <ProductCard 
                                cardTitle='Puerta de madera'
                                cardDescription='Puerta interior de madera laminada en PVC.'
                                cardPrice='USD 130'
                                cardImage={door2}
                                productSize='0.80 x 2.10'
                                productColor='Blanco'
                                offer={'30% off'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Windows Component
export default Windows;
// Import React Library
import React from 'react';

// Import Image // Import Images
import waveImage from '../assets/images/wave/wave.svg';

// FooterWave Image
const FooterWave = () => {
    return (
        <img src={waveImage} alt='Background image' className='backgorund-wave'/>
    );
};

// Export FooterWave Image
export default FooterWave;
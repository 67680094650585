// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Import Coatings Images
import coating1 from '../assets/images/coatings/wpc-1.jpg';
import coating2 from '../assets/images/coatings/wpc-2.png';

// Coatings Page Open
const Coatings = () => {
    return (
        <>
            <Navbar />
            <Cover section='coatings' coverTitle='Revestimientos' coverSubtitle='WPC Interior - WPC Exterior - WPC Plano'/>
            <WhatsappButton />
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>WPC <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Tabla Siding WPC Exterior de Madera Ranurada.'
                                cardDescription='El sistema de Tablas de Madera WPC (Wood Plastic Composite) esta compuesto por tablas sintéticas ensamblables fabricadas en un material ecológico e innovador.'
                                cardImage={coating1}
                                cardPrice='$ 1.287'
                                productSize='3mts Largo x 12cm Ancho x 1.3cm Espesor'
                                productColor='Marron Rojizo'
                                badgePanelType='Revestimiento'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Tabla Siding WPC Exterior de Madera Plana.'
                                cardDescription='El sistema de Tablas de Madera WPC (Wood Plastic Composite) esta compuesto por tablas sintéticas ensamblables fabricadas en un material ecológico e innovador.'
                                cardImage={coating2}
                                cardPrice='$ 1.170'
                                productSize='3mts Largo x 12cm Ancho x 1.3cm Espesor.'
                                productColor='Marron Rojizo'
                                badgePanelType='Revestimiento'
                                offer={'Nuevo'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Coatings Page.
export default Coatings;
// Import React Library
import React from 'react';

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// WhatsappButton Component
const WhatsappButton = () => {
    return (
        <div className='whatsapp-icon'>
            <a href="https://wa.me/598097786020?text=Hola,%20me%20gustaría%20realizar%20una%20cotización." target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} className='telephone-icon'/>           
            </a>
        </div>
    );
};

export default WhatsappButton;
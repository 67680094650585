// Import React Library
import React from 'react';

// Import Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import BuildIcon from '@mui/icons-material/Build';

// Import React Reveal
import { Fade } from "react-awesome-reveal";

// Advantage Component
const Advantages = () => {
    return (
        <div className='advantages-section'>
            <h2>POR QUE UTILIZAR PANELES TÉRMICOS ?</h2>
            <span></span>

            <div className='advantages-row'>
                <Fade direction='up' triggerOnce={true} className='advantages-col'>
                    <div className='advantages-col'>
                        <h3>Facil y rapida instalación</h3>
                        <DoneAllIcon  className='ad-icons'/>
                        <p>Son de muy sencilla instalación. El sistema de paneles presenta las ventajas de requerir menos mano de obra y tiempo para la edificación respecto a los metodos tradicionales.</p>
                    </div>
                </Fade>
                
                <Fade direction='up' triggerOnce={true} className='advantages-col'>
                    <div className='advantages-col'>
                        <h3>Poco peso</h3>
                        <EnergySavingsLeafIcon  className='ad-icons'/>
                        <p>La espuma de Poliestireno se fabrica a partir de perlas expandidas termicamente dentro de una maquina de moldeo logrando asi un producto liviano pero resistente.</p>
                    </div>
                </Fade>
               
               <Fade direction='up' triggerOnce={true} className='advantages-col'> 
                <div className='advantages-col'>
                        <h3>Economico y Eficiente</h3>
                        <MonetizationOnIcon  className='ad-icons'/>
                        <p>Al realizar cerramientos y fachadas con paneles aislantes, reduce sustancialmente el intercambio de calor, evitando la fuga de energía, generando ahorro en el sistema de calefacción y conservación de energía.</p>
                    </div>
               </Fade>
            </div>

            <div className='advantages-row'>
                <Fade direction='up' className='advantages-col' triggerOnce={true}>
                    <div className='advantages-col'>
                        <h3>Evita la condensación</h3>
                        <WaterDropIcon  className='ad-icons'/>
                        <p>Gracias a la excelente aislación termica, los paneles de poliestireno evitan la aparición de condensación y humedades y tabiqueria que de otra forma serían muy costosas de combatir.</p>
                    </div>
                </Fade>
                
                <Fade direction='up' className='advantages-col' triggerOnce={true}>
                    <div className='advantages-col'>
                        <h3>Aislante termico</h3>
                        <DeviceThermostatIcon  className='ad-icons'/>
                        <p>Gracias a su construcción de poliestireno y chapa se logran paneles ideales para aislaciones termicas, fachadas, coberturas de viviendas, centros comerciales, galpónes, etc.</p>
                    </div>
                </Fade>
                
                <Fade direction='up' className='advantages-col' triggerOnce={true}>
                    <div className='advantages-col'>
                        <h3>Economico y Eficiente</h3>
                        <BuildIcon  className='ad-icons'/>
                        <p>Debido a como se fabrica el poliestireno, no se requiere mantenimiento adicional una vez instalado.</p>
                    </div>
                </Fade>
            </div>
        </div>
    );
};

// Export Advantages Component
export default Advantages;
// Import React Library
import React from 'react';

// Import React Reveal
import { Fade } from "react-awesome-reveal";

// Import Link react-router
import { Link } from 'react-router-dom';

// Banner Component
const Banner = () => {
    return (
        <Fade direction='up' triggerOnce={true} className='banner-section-fade'>
            <Link to='/structures'>
                <div className='banner-section'>
                    <h2>Estructuras y correas</h2>
                </div>
            </Link>
        </Fade>
    );
};

// Export Banner Component
export default Banner;
// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import ProductCard from '../components/ProductCard';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import Doors Images
import panel1 from '../assets/images/isopaneles/isopanel-1.png';
import panel2 from '../assets/images/isopaneles/isopanel-2.png';
import panel3 from '../assets/images/isopaneles/isopanel-3.png';
import panel4 from '../assets/images/isopaneles/isopanel-4.png';
import panel7 from '../assets/images/isopaneles/isopanel-7.png';
import panel9 from '../assets/images/isopaneles/isopanel-9.png';
import panel10 from '../assets/images/isopaneles/isopanel-10.png';
import panel11 from '../assets/images/isopaneles/isopanel-11.png';
import panel12 from '../assets/images/isopaneles/isopanel-12.png';
import panel13 from '../assets/images/isopaneles/isopanel-13.png';
import panel14 from '../assets/images/isopaneles/isopanel-14.png';

// Import Perfileria Images
import perfileria1 from '../assets/images/perfileria/perfileria-1.jpg';
import perfileria2 from '../assets/images/perfileria/perfileria-2.jpg';
import perfileria3 from '../assets/images/perfileria/perfileria-3.jpg';
import perfileria4 from '../assets/images/perfileria/perfileria-4.jpg';
import perfileria5 from '../assets/images/perfileria/perfileria-5.jpg';
import perfileria6 from '../assets/images/perfileria/perfileria-7.jpg';
import perfileria7 from '../assets/images/perfileria/perfileria-8.jpg';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Isopaneles Component
const Isopaneles = () => {
    return (
        <>
            <Navbar />
            <Cover section='paneles' coverTitle='Isopaneles' coverSubtitle='Trapezoidal - Engrafado - Poliestireno - Poliuretano - Foil'/>
            <WhatsappButton />
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Techos <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Isopanel de 10cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 95cm. Formato trapezoidal. Autoportancia 5.50mts. Calibre de chapa 26.'
                                cardPrice='USD 42.90 c/ml'
                                cardImage={panel4}
                                productSize='0.95mts'
                                productColor='Blanco'
                                badgePanelType='Trapezoidal polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 5cm'
                                cardDescription='Isopanel de 5cm de espesor Ancho util 95cm. Formato trapezoidal. Autoportancia 3.00mts. Calibre de chapa 26.'
                                cardPrice='USD 32.90 c/ml'
                                cardImage={panel4}
                                productSize='0.95mts'
                                productColor='Blanco'
                                badgePanelType='Trapezoidal polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de Foil (3cm)'
                                cardDescription='Isopanel de foil de 3cm de espesor de poliestireno. Ancho util 95cm. Calibre de chapa 26. Formato trapezoidal.'
                                cardPrice='USD 27.90 c/ml'
                                cardImage={panel3}
                                productSize='0.95mts'
                                productColor='Negro (Grafito)'
                                badgePanelType='Trapezoidal polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de Foil (3cm)'
                                cardDescription='Isopanel de foil de 3cm de espesor de poliestireno. Ancho util 95cm. Calibre de chapa 26. Formato trapezoidal.'
                                cardPrice='USD 27.90 c/ml'
                                cardImage={panel1}
                                productSize='0.95mts'
                                productColor='Blanco'
                                badgePanelType='Trapezoidal polietileno'
                            />

                            <ProductCard 
                                cardTitle='Chapa Galvanizada 6.50mts'
                                cardDescription='Chapa galvanizada - largo 6.50mts - calibre 26 - Ancho util 95cm - Formato trapezoidal.'
                                cardPrice='USD 64.35'
                                cardImage={panel2}
                                productSize='0.95mts'
                                productColor='Blanco'
                                badgePanelType='Trapezoidal polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 10cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 5.50mts. Calibre de chapa 24.'
                                cardPrice='USD 47.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Engrafado polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 15cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 7.50mts. Calibre de chapa 24.'
                                cardPrice='USD 51.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Engrafado polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 20cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 9.00mts. Calibre de chapa 24.'
                                cardPrice='USD 56.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Engrafado polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 25cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 10.00mts. Calibre de chapa 24.'
                                cardPrice='USD 61.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Engrafado polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 5cm PIR'
                                cardDescription='Isopanel de 5cm de espesor Ancho util 1.00mts. Formato trapezoidal. Autoportancia 3.00mts. Calibre de chapa 24.'
                                cardPrice='USD 56.90 c/m2'
                                cardImage={panel11}
                                productSize='1.00mt'
                                productColor='Terracota'
                                badgePanelType='Trapezoidal poliuretano'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 5cm PIR'
                                cardDescription='Isopanel de 5cm de espesor Ancho util 1.00mts. Formato trapezoidal. Autoportancia 3.00mts. Calibre de chapa 24.'
                                cardPrice='USD 56.90 c/m2'
                                cardImage={panel12}
                                productSize='1.00mt'
                                productColor='Gris grafito'
                                badgePanelType='Trapezoidal poliuretano'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 5cm PIR'
                                cardDescription='Isopanel de 5cm de espesor Ancho util 1.00mts. Formato trapezoidal. Autoportancia 3.00mts. Calibre de chapa 24.'
                                cardPrice='USD 56.90 c/m2'
                                cardImage={panel13}
                                productSize='1.00mt'
                                productColor='Blanco'
                                badgePanelType='Trapezoidal poliuretano'
                            />
                        </div>
                    </div>

                    <div className='products-title'>
                        <h2>Paredes <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Isopanel de 10cm'
                                cardDescription='Isopanel para pared de 10cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 47.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Pared polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 15cm'
                                cardDescription='Isopanel para pared de 15cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 51.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Pared polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 20cm'
                                cardDescription='Isopanel para pared de 20cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 56.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Pared polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 25cm'
                                cardDescription='Isopanel para pared de 25cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 61.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                                badgePanelType='Pared polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 5cm'
                                cardDescription='Isopanel para pared de 5cm de espesor. Calibre de la chapa 24. Ancho util 95cm.'
                                cardPrice='USD 32.90 c/ml'
                                cardImage={panel10}
                                productSize='0.95mts'
                                productColor='Blanco'
                                badgePanelType='Pared polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 10cm'
                                cardDescription='Isopanel para pared de 5cm de espesor. Calibre de la chapa 24. Ancho util 95cm.'
                                cardPrice='USD 42.90 c/ml'
                                cardImage={panel10}
                                productSize='0.95mts'
                                productColor='Blanco'
                                badgePanelType='Pared polietileno'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 5cm PIR'
                                cardDescription='Isopanel para pared de 5cm de espesor. Calibre de la chapa 24. Ancho util 1.10mts.'
                                cardPrice='USD 56.90 c/m2'
                                cardImage={panel14}
                                productSize='1.10mts'
                                productColor='Blanco'
                                badgePanelType='Pared poliuretano'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 10cm PIR'
                                cardDescription='Isopanel para pared de 10cm de espesor. Calibre de la chapa 24. Ancho util 1.10mts.'
                                cardPrice='USD 66.90 c/m2'
                                cardImage={panel14}
                                productSize='1.10mts'
                                productColor='Blanco'
                                badgePanelType='Pared poliuretano'
                            />
                        </div>
                    </div>

                    <div className='products-title'>
                        <h2>Perfileria <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Frontalin'
                                cardDescription='Gotero frontal de chapa galvanizada. Hecho a medida en presentaciones de 3.00mts'
                                cardPrice=''
                                cardImage={perfileria1}
                                productSize='3.00mts'
                                productColor='Blanco'
                                badgePanelType='Consulte por medidas'
                            />

                            <ProductCard 
                                cardTitle='Soleras'
                                cardDescription='Soleras de chapa galvanizada. Hechas a medida en presentaciones de 3.00mts'
                                cardPrice=''
                                cardImage={perfileria2}
                                productSize='3.00mts'
                                productColor='Blanco'
                                badgePanelType='Consulte por medidas'
                            />

                            <ProductCard 
                                cardTitle='Lateral'
                                cardDescription='Gotero lateral de chapa galvanizada. Hecho a medida en presentaciones de 3.00mts'
                                cardPrice=''
                                cardImage={perfileria3}
                                productSize='3.00mts'
                                productColor='Blanco'
                                badgePanelType='Consulte por medidas'
                            />

                            <ProductCard 
                                cardTitle='Cumbrera'
                                cardDescription='Cumbrera de chapa galvanizada. Hecha a medida en presentaciones de 3.00mts'
                                cardPrice=''
                                cardImage={perfileria4}
                                productSize='3.00mts'
                                productColor='Blanco'
                                badgePanelType='Consulte por medidas'
                            />

                            <ProductCard 
                                cardTitle='Angulo'
                                cardDescription='Angulo de chapa galvanizada. Hecha a medida en presentaciones de 3.00mts'
                                cardPrice=''
                                cardImage={perfileria5}
                                productSize='3.00mts'
                                productColor='Blanco'
                                badgePanelType='Consulte por medidas'
                            />

                            <ProductCard 
                                cardTitle='Canalon'
                                cardDescription='Canalon de chapa galvanizada. Hecha a medida en presentaciones de 3.00mts'
                                cardPrice=''
                                cardImage={perfileria6}
                                productSize='3.00mts'
                                productColor='Blanco'
                                badgePanelType='Consulte por medidas'
                            />

                            <ProductCard 
                                cardTitle='Babeta Z'
                                cardDescription='Babeta de chapa galvanizada. Hecha a medida en presentaciones de 3.00mts'
                                cardPrice=''
                                cardImage={perfileria7}
                                productSize='3.00mts'
                                productColor='Blanco'
                                badgePanelType='Consulte por medidas'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Isopaneles Component
export default Isopaneles;
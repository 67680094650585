// Import React Library
import React from 'react';

// Import React Reveal
import { Fade } from "react-awesome-reveal";

// Import Link react-router
import { Link } from 'react-router-dom';

// Make Your Moudle Component
const MakeYourModule = () => {
    return (
        <Fade direction='up' triggerOnce={true}>
            <Link to='/modules'>
                <div className='make-your-module-section'>
                    <h2>Construí tu próxima casa en Isopanel</h2>
                </div>
            </Link>
        </Fade>
    );
};

// Export Make Your Module Component
export default MakeYourModule;
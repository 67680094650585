// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Import Floor Images
import floor1 from '../assets/images/floor/floor-1.png';

// Import termostato
import termostato from '../assets/images/floor/termostato.png';

// Import niveladores
import niveladores from '../assets/images/floor/niveladores.png';

// Import Piso Flotante
import floor2 from '../assets/images/floor/piso-flotante-1.png';
import floor3 from '../assets/images/floor/piso-flotante-2.png';
import floor4 from '../assets/images/floor/piso-flotante-3.png';
import floor5 from '../assets/images/floor/piso-flotante-4.png';
import floor6 from '../assets/images/floor/piso-flotante-5.png';
import floor7 from '../assets/images/floor/piso-flotante-6.png';

// Floor Component Open
const Floor = () => {
    return (
        <>
            <Navbar />
            <Cover section='floor' coverTitle='Pisos' coverSubtitle='Pisos - Camineros - Niveladores - Zocalos - Baldosas'/>
            <WhatsappButton />
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Pisos Flotantes <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Piso flotante Pigato AC3.'
                                cardDescription='Uso interior material  MDF -  Espesor 7mm - Metros por caja 2.29.'
                                cardImage={floor2}
                                cardPrice='USD 42 (2.29 m²)'
                                productSize='7.5mm de Espesor - 2.29 m²'
                                productColor='Marron claro.'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Piso Flotante Aleman AC4 Trend Oak Grey 8mm.'
                                cardDescription='Medidas: 1376x193x8mm. Origen: Aleman. Espesor: 8mm. Resistencia: AC4. Caja: 2.12 m²'
                                cardImage={floor3}
                                cardPrice='USD 53 (2.12 m²)'
                                productSize='1376 x 193 x 8mm'
                                productColor='Marron claro.'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Piso Flotante Aleman AC4 Premium Oak Brown 8mm.'
                                cardDescription='Medidas: 1380x193x8mm. Origen: Aleman. Espesor: 8mm. Resistencia: AC4. Caja: 2.13 m²'
                                cardImage={floor4}
                                cardPrice='USD 61 (2.13 m²)'
                                productSize='1380 x 193 x 8mm'
                                productColor='Marron Oscuro.'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Piso Flotante Alto Transito Comercial Agt 8mm Altay.'
                                cardDescription='Piso flotante Altay AC4. MODELO: Altay. Espesor: 8mm. Alto transito comercial'
                                cardImage={floor5}
                                cardPrice='USD 48 (1.83 m²)'
                                productSize='1200 x 200 x 8mm'
                                productColor='Marron Oscuro.'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Piso Flotante Alto Transito Residencial Agt 7.5mm Albana 24hs Water Resistant.'
                                cardDescription='Piso flotante Albana AC3. Modelo: Albana. Espesor: 7.5mm. Alto transito residencial'
                                cardImage={floor6}
                                cardPrice='USD 42 (2.29 m²)'
                                productSize='1200 x 191 x 7.5mm'
                                productColor='Marron Claro.'
                                offer={'Nuevo'}
                            />

                            <ProductCard 
                                cardTitle='Piso Flotante Alto Transito Comercial Agt 10mm Mood Modo Water Resistant.'
                                cardDescription='Medidas: 1195 x 325 x 10mm. Origen: Turco. Espesor: 10mm. Resistencia: AC4. Caja: 1.94 m².'
                                cardImage={floor7}
                                cardPrice='USD 61 (1.94 m²)'
                                productSize='1195 x 325 x 10mm'
                                productColor='Marron Oscuro.'
                                offer={'Nuevo'}
                            />
                        </div>
                    </div>

                    <div className='products-title'>
                        <h2>calefacción <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Manta losa Radiante  150w - 2m2.'
                                cardDescription='Manta losa Radiante  150w - 2m2 - Ancho 50cm.'
                                cardImage={floor1}
                                cardPrice='USD 39.80'
                                productSize='2m2'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Manta losa Radiante  150w - 3m2.'
                                cardDescription='Manta losa Radiante  150w - 3m2 - Ancho 50cm.'
                                cardImage={floor1}
                                cardPrice='USD 59.70'
                                productSize='3m2'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Manta losa Radiante  150w - 9m2.'
                                cardDescription='Manta losa Radiante  150w - 9m2 - Ancho 50cm.'
                                cardImage={floor1}
                                cardPrice='USD 179.10'
                                productSize='9m2'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Manta losa Radiante  150w - 12m2.'
                                cardDescription='Manta losa Radiante  150w - 12m2 - Ancho 50cm.'
                                cardImage={floor1}
                                cardPrice='USD 238.90'
                                productSize='12m2'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Termostato digital.'
                                cardDescription='Termostato digital para calefacción de suelo, programable, LCD, M6, 220V, controlador de suelo cálido.'
                                cardImage={termostato}
                                cardPrice='USD 20'
                                productSize=''
                                productColor='Color'
                            />
                        </div>
                    </div>

                    <div className='products-title'>
                        <h2>Niveladores <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Kit nivelador de piso.'
                                cardDescription='Sistema de niveladores roscados para cerámica y porcelanato, incluye 50 niveladores y 1 herramienta para instalación.'
                                cardImage={niveladores}
                                cardPrice='USD 17.50'
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Kit nivelador de piso.'
                                cardDescription='Sistema de niveladores roscados para cerámica y porcelanato, incluye 100 niveladores y 100 cunas y 1 pinza niveladora.'
                                cardImage={niveladores}
                                cardPrice='USD 32'
                                productSize='N/A'
                                productColor='N/A'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Floor Component
export default Floor;
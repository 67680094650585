// Import React Library
import React from 'react';

// Divider Component
const Divider = () => {
    return (
        <div className='divider-footer'></div>
    );
};

// Export Divider Component
export default Divider;
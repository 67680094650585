// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import images
import module1 from '../assets/images/modules/26m.jpg';
import module2 from '../assets/images/modules/40m.jpg';
import module3 from '../assets/images/modules/54m.jpg';

// Import React Reveal
import { Fade } from "react-awesome-reveal";

// Import Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Make Your Own Module Component
const MakeYourOwnModule = () => {
    return (
        <>
            <Navbar />
            <Cover section='modules' coverTitle='Construí tu casa en Isopanel' coverSubtitle='Llave en mano - Materiales - Mano de obra'/>
            <div className='modules-title'>
                <h2>Tu casa en isopanel</h2>
                <span></span>
                <p>
                    Te ofrecemos una variedad de opciones para que tengas tu casa de isopanel. Casa llave en mano, montaje de la estructura, suministro de materiales, a medida, personalizamos tus preferencias. Consultenos por su proyectos.
                </p>
            </div>
            
            <Fade direction='left' triggerOnce={true}> 
                <div className='make-your-own-module'>
                    <div className='module-container'>
                        <img src={module1} alt='Monoambiente'/>

                        <div className='module-data'>
                            <h2>Monoambiente (26m²)</h2>
                            <span></span>
                            
                            <div className='module-data-p'>
                                <p><CheckCircleIcon className='list-icon'/> Platea de hormigón</p>
                                <p><CheckCircleIcon className='list-icon'/> Paredes y techo de Isopanel</p>
                                <p><CheckCircleIcon className='list-icon'/> Aberturas de aluminio</p>
                                <p><CheckCircleIcon className='list-icon'/> Instalación eléctrica</p>
                                <p><CheckCircleIcon className='list-icon'/> Instalación Sanitaria</p>
                                <p><CheckCircleIcon className='list-icon'/> Pozo negro o conexión a saneamiento</p>
                                <p><CheckCircleIcon className='list-icon'/> Totalmente terminada</p>
                                <p><CheckCircleIcon className='list-icon'/> Llave en mano</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
            
            <Fade direction='right' triggerOnce={true}> 
                <div className='make-your-own-module'>
                    <div className='module-container'>
                        <div className='module-data'>
                            <h2>Un Dormitorio (40m²)</h2>
                            <span></span>
                            
                            <div className='module-data-p'>
                                <p><CheckCircleIcon className='list-icon'/> Platea de hormigón</p>
                                <p><CheckCircleIcon className='list-icon'/> Paredes y techo de Isopanel</p>
                                <p><CheckCircleIcon className='list-icon'/> Aberturas de aluminio</p>
                                <p><CheckCircleIcon className='list-icon'/> Instalación eléctrica</p>
                                <p><CheckCircleIcon className='list-icon'/> Instalación Sanitaria</p>
                                <p><CheckCircleIcon className='list-icon'/> Pozo negro o conexión a saneamiento</p>
                                <p><CheckCircleIcon className='list-icon'/> Totalmente terminada</p>
                                <p><CheckCircleIcon className='list-icon'/> Llave en mano</p>
                            </div>
                        </div>

                        <img src={module2} alt='Un Dormitorios'/>
                    </div>
                </div>
            </Fade>

            <Fade direction='left' triggerOnce={true}> 
                <div className='make-your-own-module'>
                    <div className='module-container'>
                        <img src={module3} alt='Dos Dormitorios'/>

                        <div className='module-data'>
                            <h2>Dos Dormitorios (54m²)</h2>
                            <span></span>
                            
                            <div className='module-data-p'>
                                <p><CheckCircleIcon className='list-icon'/> Platea de hormigón</p>
                                <p><CheckCircleIcon className='list-icon'/> Paredes y techo de Isopanel</p>
                                <p><CheckCircleIcon className='list-icon'/> Aberturas de aluminio</p>
                                <p><CheckCircleIcon className='list-icon'/> Instalación eléctrica</p>
                                <p><CheckCircleIcon className='list-icon'/> Instalación Sanitaria</p>
                                <p><CheckCircleIcon className='list-icon'/> Pozo negro o conexión a saneamiento</p>
                                <p><CheckCircleIcon className='list-icon'/> Totalmente terminada</p>
                                <p><CheckCircleIcon className='list-icon'/> Llave en mano</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
            <WhatsappButton />
            <FooterWave />
            <Footer />
        </>
    );
};

// Export MakeYourOwnModule
export default MakeYourOwnModule;
// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Import Firebase Aut
import { signInWithEmailAndPassword } from 'firebase/auth';
import{auth} from '../firebase/firebase.config';

// Import Images
import waveImage from '../assets/images/wave/wave.svg';
import blob from '../assets/images/blob/blob.svg';
import blob2 from '../assets/images/blob/blob1.svg';

// Import Alerts
import Alert from '@mui/material/Alert';

// Import Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Import MUI inputs
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Login component 
const Login = () => {

    // Handle Password Input
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    // Component States
    const [emailRegister, setEmailRegister] = React.useState('');
    const [passwordRegister, setPasswordRegister] = React.useState('');

    const [successMessage , setSuccessMessage] = React.useState(false);
    const [errorMessage , setErrorMessage] = React.useState(false);
    const [alertMessage , setAlertMessage] = React.useState('');

    // Handle Login
    const handleLogin = async (e) =>{
        e.preventDefault();

        e.preventDefault();
        try {
          await signInWithEmailAndPassword(auth, emailRegister, passwordRegister);
          setSuccessMessage(true);
          setAlertMessage('Se ha logueado correctamente');
          console.log('User logged in successfully');

          document.querySelector('.')

          setTimeout(() =>{
            setErrorMessage(false);
            setSuccessMessage(false);
        }, 3000)
        } catch (error) {
            setErrorMessage(true);
            setAlertMessage('Usuario o contraseña incorrecta');
            console.log(error.message);

            setTimeout(() =>{
                setErrorMessage(false);
                setSuccessMessage(false);
            }, 3000)
        }
    }

    return (
        <>
            <Navbar />
            <div className='login-section'>
                <div className='login-container'>
                    { successMessage ? 
                            <Alert variant="filled" severity="success" className='form-alert'>
                                {alertMessage}
                            </Alert> : ''
                    }

                    { errorMessage ? 
                        <Alert variant="filled" severity="error" className='form-alert'>
                            {alertMessage}
                        </Alert> : ''
                    }

                   <h2>Login</h2>
                   <AccountCircleIcon  className='login-icon'/>

                   <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '40ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                        <div>
                            <TextField
                                id="user-input"
                                label="User"
                                defaultValue=""
                                onChange={(e) => setEmailRegister(e.target.value)}
                            />
                        </div>

                        <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                className="password-input"
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => setPasswordRegister(e.target.value)}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </Box>

                    <button className='login-button' type='button' onClick={(e)=> handleLogin(e)}>Login</button>
                </div>
            </div>

            <img src={waveImage} alt='Background image' className='backgorund-wave'/>
            <img src={blob} alt='Blob' className='blob-image'/>
            <img src={blob2} alt='Blob' className='blob-image-2'/>
            <Footer />
        </>
    );
};

// Export Login Component
export default Login;
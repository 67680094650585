// Import Reac Library
import React from 'react';

// Import Icons
import HomeIcon from '@mui/icons-material/Home';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FactoryIcon from '@mui/icons-material/Factory';
import EditNoteIcon from '@mui/icons-material/EditNote';

// Projects Component
const Projects = () => {
    return (
        <div className='projects-section'>
            <h2>Proyectos</h2>
            <span></span>

            <div className='projects-type'>
                <div className='project-container'>
                    <HomeIcon className='project-icon'/>
                    <h2>Proyectos Residenciales</h2>
                </div>

                <div className='project-container'>
                    <WarehouseIcon className='project-icon'/>
                    <h2>Proyectos Comerciales</h2>
                </div>

                <div className='project-container'>
                    <FactoryIcon className='project-icon'/>
                    <h2>Proyectos Industriales</h2>
                </div>

                <div className='project-container'>
                    <EditNoteIcon className='project-icon'/>
                    <h2>Proyectos a Medida</h2>
                </div>
            </div>
        </div>
    );
};

// Import Projects 
export default Projects;
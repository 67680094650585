// Import React Library
import * as React from 'react';
import {useState} from 'react';

// Import Material UI Components
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

// Import Bootstrap Modal
import Modal from 'react-bootstrap/Modal';

// Import Images
import constrution1 from '../assets/images/constructions/construction-1.jpeg';
import construction2 from '../assets/images/constructions/construction-2.jpeg';
import construction3 from '../assets/images/constructions/construction-3.jpeg';
import construction4 from '../assets/images/constructions/construction-4.jpeg';
import construction5 from '../assets/images/constructions/construction-5.jpg';
import construction6 from '../assets/images/constructions/construction-6.jpg';
import construction7 from '../assets/images/constructions/construction-7.jpg';
import construction8 from '../assets/images/constructions/construction-8.png';
import construction9 from '../assets/images/constructions/construction-9.png';
import construction10 from '../assets/images/constructions/construction-10.png';
import construction11 from '../assets/images/constructions/construction-11.png';
import construction12 from '../assets/images/constructions/construction-12.png';
import construction13 from '../assets/images/constructions/construction-13.png';
import construction14 from '../assets/images/constructions/construction-14.png';
import construction15 from '../assets/images/constructions/construction-15.png';
import construction16 from '../assets/images/constructions/construction-16.png';
import construction17 from '../assets/images/constructions/construction-17.png';
import construction18 from '../assets/images/constructions/construction-18.png';
import construction19 from '../assets/images/constructions/construction-19.png';
import construction20 from '../assets/images/constructions/construction-20.png';
import construction21 from '../assets/images/constructions/construction-21.png';
import construction22 from '../assets/images/constructions/construction-22.png';
import construction23 from '../assets/images/constructions/construction-23.png';
import construction24 from '../assets/images/constructions/construction-24.png';
import construction25 from '../assets/images/constructions/construction-25.png';
import construction26 from '../assets/images/constructions/construction-26.png';
import construction27 from '../assets/images/constructions/construction-27.png';
import construction28 from '../assets/images/constructions/construction-28.png';
import construction29 from '../assets/images/constructions/construction-29.png';
import construction30 from '../assets/images/constructions/construction-30.png';
import construction31 from '../assets/images/constructions/construction-31.png';
import construction32 from '../assets/images/constructions/construction-32.png';
import construction33 from '../assets/images/constructions/construction-33.png';
import construction34 from '../assets/images/constructions/construction-34.png';

export default function ConstructionsGrid() {
    // Handle Modal Component State 
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [constructionImage , setConstructionImage] = useState('');
    const [constructionTitle , setConstructionTitle] = useState('');

    function handleShow(breakpoint , imgRoute, title) {
        setFullscreen(breakpoint);
        setShow(true);
        setConstructionImage(imgRoute);
        setConstructionTitle(title);
    }

    return (
        <>
            <ImageList sx={{ width: '80%', height: '100%' }}>
            {itemData.map((item) => (
                <ImageListItem key={item.img}>
                <img
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                    onClick={() => handleShow(true , item.img, item.title)}
                    style={{cursor: 'pointer'}}
                />
                <ImageListItemBar
                    title={item.title}
                    subtitle={item.author}
                    actionIcon={
                    <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                    >
                        <InfoIcon />
                    </IconButton>
                    }
                />
                </ImageListItem>
            ))}
            </ImageList>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} className='product-modal'>
                <Modal.Header closeButton>{constructionTitle}</Modal.Header>
                <Modal.Body>
                    <img src={constructionImage} alt='Construction Image' className='modal-image'/>
                </Modal.Body>
            </Modal>
        </>
    );
}

const itemData = [
  {
    img: constrution1,
    title: 'Feria de la construccion',
    author: 'Stand Feria de la construccion',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: construction2,
    title: 'Galpon',
    author: 'Galpon Ruta 101',
  },
  {
    img: construction5,
    title: 'Galpones',
    author: 'Galpones en parque logistico Ventura',
  },
  {
    img: construction4,
    title: 'Galpones',
    author: 'Galpones en parque logistico Ventura',
    cols: 2,
  },
  {
    img: construction3,
    title: 'Galpon',
    author: 'Galpon Ruta 101',
  },
  {
    img: construction6,
    title: 'Galpon',
    author: 'Galpon en parque logistico Ventura',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: construction7,
    title: 'Galpon',
    author: 'Galpon en parque logistico Ventura',
  },
  {
    img: construction8,
    title: 'Galpon',
    author: 'Galpon en parque logistico Ventura',
  },
  {
    img: construction9,
    title: 'Entrada parque Ventura',
    author: 'Entrada de ingreso Parque logistico Ventura',
    rows: 2,
    cols: 2,
  },
  {
    img: construction10,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction11,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction12,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    cols: 2,
  },
  {
    img: construction13,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: construction14,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction15,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction16,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    rows: 2,
    cols: 2,
  },
  {
    img: construction17,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction18,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction19,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    cols: 2,
  },
  {
    img: construction20,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: construction21,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction22,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction23,
    title: 'Techo',
    author: 'Techo en Salinas',
    rows: 2,
    cols: 2,
  },
  {
    img: construction24,
    title: 'Techo',
    author: 'Techo en Salinas',
  },
  {
    img: construction25,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction26,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    cols: 2,
  },
  {
    img: construction27,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: construction28,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
  },
  {
    img: construction29,
    title: 'Modulo',
    author: 'Modulo de 6x3 en Neptunia',
  },
  {
    img: construction30,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    rows: 2,
    cols: 2,
  },
  {
    img: construction31,
    title: 'Mesada y aberturas',
    author: 'Mesada y aberturas en Colonia Nicolich',
  },
  {
    img: construction32,
    title: 'Mesada y aberturas',
    author: 'Mesada y aberturas en Colonia Nicolich',
  },
  {
    img: construction33,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    cols: 2,
  },
  {
    img: construction34,
    title: 'Casa en barrio privado',
    author: 'Casa en Colina de Carrasco',
    rows: 2,
    cols: 2,
    featured: true,
  }
];

// Import React Library
import React from 'react';

// Cover component
const Cover = ({section, coverTitle, coverSubtitle}) => {
    return (
        <section className={section + '-cover-section cover-section'}>
            {coverTitle ? <h1>{coverTitle}</h1> : <h1>PreHouse Uruguay</h1>}

            {coverSubtitle ? <h2>{coverSubtitle}</h2> : <h2>Isopaneles | Construcciones | Hierros | Aberturas | Pisos | Herramientas</h2>}
        </section>
    );
};

// Export Cover component
export default Cover;
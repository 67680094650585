// Import React Library
import React from 'react';

// Import Images
import prehouseCoverUno from '../assets/images/pre-house-cover-2.jpeg';
import prehouseCoverDos from '../assets/images/pre-house-cover-3.jpeg';
import prehouseCoverTres from '../assets/images/pre-house-cover-4.jpeg';
import prehouseCoverCuatro from '../assets/images/pre-house-cover-5.jpeg';
import prehouseCoverCinco from '../assets/images/pre-house-cover-6.jpeg';

// Marquee Component
const Marquee = () => {
    return (
        <div class="marquee">
            <div class="marquee-content">
                <img src={prehouseCoverCinco} alt="Instalaciones Sanatorio" />
                <img src={prehouseCoverUno} alt="Instalaciones Sanatorio" />
                <img src={prehouseCoverDos} alt="Instalaciones Sanatorio" />
                <img src={prehouseCoverCuatro} alt="Instalaciones Sanatorio" />
                <img src={prehouseCoverTres} alt="Instalaciones Sanatorio" />
            </div>
        </div>
    );
};

// Export Marquee Component
export default Marquee;
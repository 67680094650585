// Import React Library
import React from 'react';

// Import Icons
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

// Import Components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Error component
const Error = () => {
    return (
        <> 
            <Navbar />
            <div className='error-container'>
                <SentimentVeryDissatisfiedIcon  className='error-icon-face'/>
                <h2>Error 404 - Page not Found.</h2>
            </div>
            <WhatsappButton />
            <FooterWave />
            <Footer/>
        </>
    );
};

// Export error component
export default Error;
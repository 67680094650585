// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Hot Iron Component
const HotIron = () => {
    return (
        <>
            <Navbar />
            <Cover section='steel' coverTitle='Caños Galvanizados en caliente' coverSubtitle='Caños cuadrados - Caños Rectangulares'/>
            <WhatsappButton />
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Hot Iron Component
export default HotIron;
// Import React Library
import React from 'react';

// React icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Import Components
import Divider from './Divider';

// Footer Component
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-row'>
                <div className='footer-col'>
                    <AccessTimeIcon fontSize='large' className='footer-icon'/>            
                    <p>Local Barros Blancos</p>
                    <br />
                    <p>Lunes a Viernes</p>
                    <br />
                    <p>09:00 a 17:00hs</p>
                </div>

                <div className='footer-col'>
                    <MeetingRoomIcon fontSize='large' className='footer-icon'/>            
                    <p>Deposito camino ventura</p>
                    <br /> 
                    <p>Lunes a Viernes</p>
                    <br />
                    <p>09:00 a 17:00hs.</p>
                </div>

                <div className='footer-col'>
                    <RingVolumeIcon fontSize='large' className='footer-icon'/>            
                    <p>Telefono:</p>
                    <br />
                    <p>099 384 353 - 2289 11 12</p>
                </div>

                <div className='footer-col'>
                    <a href='https://www.instagram.com/prehouseuruguay' target="_blank" rel="noopener noreferrer">
                        <InstagramIcon fontSize='large' className='footer-icon'/>            
                        <p>Instagram</p>
                        <br />
                        <p>@prehouseuruguay</p>
                    </a>
                </div>

                <div className='footer-col'>
                    <LocationOnIcon fontSize='large' className='footer-icon'/>            
                    <p>Ubicacion</p>
                    <br />
                    <p>Ruta 8 KM 27.100 parada 33</p>
                </div>
            </div>

            <Divider />

            <div className='footer-row'>
                © 2024 - PreHouse Uruguay . Todos los derechos reservados.
            </div>
        </div>
    );
};

// Export Footer Component
export default Footer;